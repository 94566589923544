
import {
    defineComponent, onBeforeMount, PropType, reactive,
} from 'vue';
import Transaction from '@/domain/Transaction';
import { Direction } from '@/domain/TransactionAbstractShare';
import { TransactionStatus } from '@/domain/TransactionStatus';
import TransactionService from '@/services/TransactionService';
import SupplierOrderTransactionScreen from '@/modules/partner-engagement/view/supplier-order/screens/SupplierOrderTransactionScreen.vue';
import SupplierShippingTransactionScreen from '@/modules/partner-engagement/view/supplier-shipping/screens/SupplierShippingTransactionScreen.vue';
import coreStore from '@/store/CoreStore';
import ConfirmDisputeTransactionScreen from '@/modules/partner-engagement/view/supplier-shipping/screens/ConfirmDisputeTransactionScreen.vue';

type State = {
    transaction: Transaction | null;
    loading: boolean;
};

export default defineComponent({
    name: 'pem-transaction-edit-screen-dispatcher',
    components: {
        SupplierShippingTransactionScreen,
        SupplierOrderTransactionScreen,
        ConfirmDisputeTransactionScreen,
    },
    props: {
        transactionId: {
            type: String,
            default: undefined,
        },
        transactionType: {
            type: String as PropType<Direction>,
            default: undefined,
        },
    },
    setup(props) {
        const { userLocation } = coreStore.getInstance().profileStore;
        const transactionService = new TransactionService();

        const state = reactive<State>({
            transaction: null,
            loading: true,
        });

        async function loadExisting(transactionId: number) {
            const response = await transactionService.getTransactionById(transactionId);

            if (response) {
                state.transaction = response.transaction;
            }
        }

        function initInbound() {
            state.transaction = new Transaction();
            state.transaction.toLocation = userLocation;
            state.transaction.status = TransactionStatus.ORDERED;
        }

        function loadNew() {
            if (props.transactionType) {
                if (props.transactionType === 'inbound') {
                    initInbound();
                }
            }
        }

        onBeforeMount(async () => {
            state.loading = true;

            if (props.transactionId) {
                await loadExisting(parseInt(props.transactionId, 10));
            } else {
                loadNew();
            }

            state.loading = false;
        });

        return {
            state,
            TransactionStatus,
            userLocation,
        };
    },
});
