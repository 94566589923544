
import {
    defineComponent, onBeforeMount, reactive, toRef, computed,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import Transaction from '@/domain/Transaction';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import TransactionService from '@/services/TransactionService';
import DateInput from '@/components/inputs/DateInput.vue';
import InventoryFlatDTO from '@/dtos/InventoryFlatDTO';
import TextInput from '@/components/inputs/TextInput.vue';
import router from '@/router';
import Popover from '@/components/bootstrap-library/Popover.vue';
import useValidator from '@/validation/useValidator';
import TrailerType from '@/domain/TrailerType';
import TrailerTypeService from '@/services/TrailerTypeService';
import { MaxTrailerProps, useMaxTrailerCompute } from '@/composable/useMaxTrailerCompute';
import { ItemTableData } from '@/domain/ItemTableData';
import { useNotification } from '@/composable/useNotifications';
import TransactionSaveShipNowButtons from '@/components/buttons/TransactionSaveShipNowButtons.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import TransactionFooter from '@/components/TransactionFooter.vue';
import useSupplierOrder from '@/composable/useSupplierOrder';
import Item from '@/domain/Item';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import NonWorkDayService from '@/services/NonWorkDayService';
import coreStore from '@/store/CoreStore';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import { Formatter } from '@/domain/TransactionStatus';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    itemList: Array<ItemTableData>;
    loading: boolean;
    saving: boolean;
    preferredTrailerType: TrailerType;
    currentInventory: Array<InventoryFlatDTO>;
    componentKey: number;
};

export default defineComponent({
    name: 'supplier-order-transaction-screen',
    components: {
        Thumbnail,
        Popover,
        DateInput,
        Screen,
        TextInput,
        BTable,
        NumberInput,
        TransactionFooter,
        TransactionSaveShipNowButtons,
    },
    props: {
        modelValue: {
            type: Transaction,
            default: () => new Transaction(),
        },
    },
    setup(props) {
        const transactionService = new TransactionService();
        const trailerTypeService = new TrailerTypeService();
        const coreStoreInstance = coreStore.getInstance();
        const { timeSetStore, inventoryCategoryStore } = coreStoreInstance;
        const nonWorkDayService = new NonWorkDayService(timeSetStore.allTimeSets);
        const notification = useNotification();

        const { setItemData } = useSupplierOrder(props.modelValue);

        const { validateForm, validationResult } = useValidator<Transaction>('transaction-order');

        const state = reactive<State>({
            itemList: [],
            loading: false,
            saving: false,
            preferredTrailerType: new TrailerType(),
            currentInventory: [],
            componentKey: 0,
        });

        const calendarData = nonWorkDayService.getNeedByDateCalendarData(props.modelValue.toLocation.nonWorkingTimeSetId);

        const itemList = computed(
            (): Array<ItemTableData> => {
                const items = state.itemList.map((row) => row.item);
                return ItemTableData.BuildOrderPackagingTableData(items, props.modelValue as Transaction, state.currentInventory);
            },
        );

        const fields: Array<BTableField<ItemTableData & { quantity: string }>> = [
            { key: 'imageUrlThumb', label: getTitleCaseTranslation('core.common.image') },
            { key: 'name', label: getTitleCaseTranslation('core.domain.name') },
            { key: 'shortName', label: getTitleCaseTranslation('core.domain.shortName') },
            { key: 'quantity', label: getTitleCaseTranslation('core.domain.quantity') },
            { key: 'quantityOnHand', label: getTitleCaseTranslation('core.domain.quantityOnHand') },
            { key: 'palletQty', label: getTitleCaseTranslation('core.domain.palletQty') },
        ];

        const composableProps: MaxTrailerProps = {
            itemList,
            trailerType: toRef(state, 'preferredTrailerType'),
            itemQtyKey: 'plannedQuantity',
        };

        const maxTrailerComposable = useMaxTrailerCompute(composableProps);

        async function fetchPreferredTrailerType() {
            const trailerType = await trailerTypeService.getPreferredTrailerTypeForLocation(props.modelValue.toLocationId);
            if (trailerType) {
                state.preferredTrailerType = trailerType;
            }
        }

        onBeforeMount(async () => {
            state.loading = true;
            const finishedGoodsCategory = inventoryCategoryStore.getFinishedGoodsCategory();
            if (props.modelValue.toLocationId) {
                await setItemData((locationItems: Item[], inventoryData: InventoryGroupedDTO[]) => {
                    state.currentInventory = inventoryData.map(
                        (groupedInventory: InventoryGroupedDTO): InventoryFlatDTO => new InventoryFlatDTO({
                            itemId: groupedInventory.itemId,
                            locationId: groupedInventory.locationId,
                            quantity: finishedGoodsCategory ? groupedInventory.getInventoryForCategory(finishedGoodsCategory) : 0,
                        }),
                    );
                    state.itemList = ItemTableData.BuildOrderPackagingTableData(locationItems, props.modelValue, state.currentInventory);
                });
            }

            await fetchPreferredTrailerType();

            state.loading = false;
        });

        function goToTransactionList() {
            router.push({ name: PartnerEngagementRouteTypes.TRANSACTION.LISTALL });
        }

        async function saveTransaction() {
            validateForm(props.modelValue);

            if (validationResult.isValid) {
                if (maxTrailerComposable.isOverCapacity.value) {
                    notification.showWarning('Trailer is over capacity');
                } else {
                    state.saving = true;

                    let response;
                    if (props.modelValue.id) {
                        response = await transactionService.updateTransaction(props.modelValue);
                    } else {
                        response = await transactionService.saveOrderedTransaction(props.modelValue);
                    }
                    if (response.success) {
                        goToTransactionList();
                    }
                }
                state.saving = false;
            }
        }

        function goBack() {
            goToTransactionList();
        }

        function hasRemainder(requestedQty: number, palletQty: number): boolean {
            return requestedQty % palletQty !== 0;
        }

        function roundUp(requestedQty: number, palletQty: number): number {
            return Math.ceil(requestedQty / palletQty) * palletQty;
        }

        function onQtyChange(qty: number, item: ItemTableData) {
            item.warningMessage = [];
            let plannedQty = 0;
            if (Number.isNaN(qty)) {
                return;
            }
            if (item.palletQty && hasRemainder(qty, item.palletQty)) {
                item.warningMessage.unshift(`${qty} ${getTranslation('core.domain.requestedQuantityRoundedUp')}`);
                plannedQty = roundUp(qty, item.palletQty);
                state.componentKey++;
            } else {
                plannedQty = qty;
            }

            props.modelValue.setPlannedItemQty(item.item, plannedQty);
            props.modelValue.setRequestedItemQty(item.item, qty);
        }

        const navbarTitle = computed((): string => {
            if (props.modelValue.id) {
                return getTranslation('core.common.transactionNumberTitle', props.modelValue.id, Formatter.GetFriendlyValue(props.modelValue.status));
            }
            return getTitleCaseTranslation('core.button.orderPackaging');
        });

        return {
            state,
            goToTransactionList,
            saveTransaction,
            onQtyChange,
            goBack,
            validationResult,
            calendarData,
            maxTrailerComposable,
            navbarTitle,
            getTitleCaseTranslation,
            fields,
        };
    },
});
