import Item from '@/domain/Item';
import Transaction from '@/domain/Transaction';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import InventoryService from '@/services/InventoryService';
import RouteConfigService from '@/services/RouteConfigService';

export interface UseSupplierOrder {
    setItemData: (tablePopulationCallback: (locationItems: Item[], inventoryData: InventoryGroupedDTO[]) => void) => Promise<void>
}

export default function useSupplierOrder(modelValue: Transaction) : UseSupplierOrder {
    const inventoryService = new InventoryService();
    const routeConfigService = new RouteConfigService();

    async function setItemData(
        tablePopulationCallback: (locationItems: Item[], inventoryData: InventoryGroupedDTO[]) => void,
    ) {
        const LocationItemsResponse = await routeConfigService.getAllItemsByToLocation(modelValue.toLocationId);
        const inventoryData = await inventoryService.getAllLocationInventory(modelValue.toLocationId);

        if (LocationItemsResponse && inventoryData.success) {
            tablePopulationCallback(LocationItemsResponse, inventoryData.inventory);
        }
    }

    return {
        setItemData,
    };
}
