import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13fad77a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "transaction-content"
}
const _hoisted_2 = { class: "error" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = {
  key: 0,
  class: "warning-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_popover = _resolveComponent("popover")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_transaction_save_ship_now_buttons = _resolveComponent("transaction-save-ship-now-buttons")!
  const _component_transaction_footer = _resolveComponent("transaction-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    id: "create-supplier-order-screen",
    full: "",
    title: _ctx.navbarTitle,
    "go-back": _ctx.goBack
  }, {
    footer: _withCtx(() => [
      (!_ctx.state.loading)
        ? (_openBlock(), _createBlock(_component_transaction_footer, {
            key: 0,
            transaction: _ctx.modelValue,
            "max-trailer-composable": _ctx.maxTrailerComposable
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_transaction_save_ship_now_buttons, {
                "can-edit": !_ctx.modelValue.hasShipped,
                "can-ship-now": false,
                saving: _ctx.state.saving,
                transaction: _ctx.modelValue,
                onSubmit: _ctx.saveTransaction
              }, null, 8, ["can-edit", "saving", "transaction", "onSubmit"])
            ]),
            _: 1
          }, 8, ["transaction", "max-trailer-composable"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_b_row, { style: {"margin":"25px -15px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.toLocation.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.toLocation.name) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.toLocation'),
                  md: "3",
                  disabled: ""
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_date_input, {
                  modelValue: _ctx.modelValue.dateRequired,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.dateRequired) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.needBy'),
                  md: "3",
                  error: _ctx.validationResult.model.dateRequired,
                  "default-time": "12:00:00",
                  "disabled-dates": _ctx.calendarData.needByDateDisabled,
                  "upper-limit": _ctx.calendarData.needByDateMax,
                  "lower-limit": _ctx.calendarData.needByDateMin
                }, null, 8, ["modelValue", "label", "error", "disabled-dates", "upper-limit", "lower-limit"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.partnerReferenceNumber,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.partnerReferenceNumber) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.partnerReferenceNumber'),
                  placeholder: "#",
                  md: "3",
                  error: _ctx.validationResult.model.partnerReferenceNumber
                }, null, 8, ["modelValue", "label", "error"])
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.validationResult.model.transactionLines), 1),
            _createVNode(_component_b_table, {
              "table-key": (_ctx.modelValue.fromLocationId + _ctx.modelValue.toLocationId).toString(),
              items: _ctx.state.itemList,
              fields: _ctx.fields
            }, {
              "cell(imageUrlThumb)": _withCtx((row) => [
                _createVNode(_component_thumbnail, {
                  "image-url-thumb": row.item.imageUrlThumb,
                  "image-url-full": row.item.imageUrlFull
                }, null, 8, ["image-url-thumb", "image-url-full"])
              ]),
              "cell(quantity)": _withCtx((row) => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_number_input, {
                    key: _ctx.state.componentKey,
                    "model-value": row.item.plannedQuantity,
                    style: {"max-width":"120px","margin":"10px 0"},
                    step: row.item.palletQty,
                    "hide-stepper": "",
                    onChange: (qty) => _ctx.onQtyChange(qty, row.item)
                  }, null, 8, ["model-value", "step", "onChange"]),
                  (row.item.warningMessage.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_popover, null, {
                          content: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.warningMessage, (msg, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, [
                                _createElementVNode("span", null, _toDisplayString(msg), 1)
                              ]))
                            }), 128))
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_faicon, { icon: "exclamation-circle" })
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              empty: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation(_ctx.modelValue.toLocationId ? 'core.common.noItemsFound' : 'core.common.selectLocation')), 1)
              ]),
              _: 1
            }, 8, ["table-key", "items", "fields"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}